import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as hoverBoxStyles from './hoverbox.module.css'
import { useTranslation } from 'react-i18next'

interface HoverBoxProps {}

export const HoverBox: React.FC<HoverBoxProps> = () => {
    const { t }: { t: any } = useTranslation()
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    socials {
                        key
                        name
                        url
                        profiles {
                            handle
                            description
                        }
                    }
                    professions {
                        key
                    }
                }
            }
        }
    `)

    return (
        <div className={hoverBoxStyles.wrapper}>
            <img src="/images/me-500.jpg" className={hoverBoxStyles.image} />
            <h2>{data.site.siteMetadata.title}</h2>
            <div
                className={hoverBoxStyles.professions_wrapper}
                style={{ alignItems: 'flex-start', alignSelf: 'flex-start' }}
            >
                {data.site.siteMetadata.professions.map((profession: any) => (
                    <div
                        className={hoverBoxStyles.profession}
                        key={profession.key}
                    >
                        <span>{t(`PROFESSION.${profession.key}`)}</span>
                    </div>
                ))}
            </div>
            <div>
                <h3>{t('SELFIMAGE.HEADER')}</h3>
                <p>{t('SELFIMAGE.TEXT')}</p>
            </div>
            <div
                className={hoverBoxStyles.social_wrapper}
                style={{ alignItems: 'flex-start' }}
            >
                {data.site.siteMetadata.socials.map((social: any) => (
                    <div className={hoverBoxStyles.social} key={social.key}>
                        <img src={`/images/social/${social.key}.svg`} />
                        <div className={hoverBoxStyles.social_profiles_wrapper}>
                            {social.profiles.map((profile: any) => (
                                <a
                                    key={profile.handle}
                                    className={hoverBoxStyles.social_profile}
                                    href={`${social.url}/${profile.handle}`}
                                >
                                    @{profile.handle}
                                </a>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
